import React from 'react'
import "./Insurance.css"
import NavbarMain from "../../pages/NavbarMain/NavbarMain"

import Partnership from "../../pages/Partnership/Partnership"

import Swal from 'sweetalert2';
// import { toast } from 'react-toastify';  


import "../../pages/Questions/Faq.css";
import { useState } from 'react';
import 'remixicon/fonts/remixicon.css';
import questionMarkImg from "../../assets/questionMark.png"
import Footer from '../../pages/Footer/Footer';


const Insurance = () => {

  // --------------------------------email condition-----------------------------------------------------

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Real-time field validation function
  const validateField = (name, value) => {
    const newErrors = { ...errors };
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (name) {
      case 'name':
        newErrors.name = value ? '' : 'Name is required';
        break;
      case 'phone':
        newErrors.phone = value
          ? phoneRegex.test(value)
            ? ''
            : 'Phone number must be 10 digits'
          : 'Phone number is required';
        break;
      case 'email':
        newErrors.email = value
          ? emailRegex.test(value)
            ? ''
            : 'Email is not valid'
          : 'Email is required';
        break;
      case 'message':
        newErrors.message = value ? '' : 'Message is required';
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Call validation function on each input change
    validateField(name, value);
  };

  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) {
      newErrors.name = 'Name is required';
    }

    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Email is not valid';
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); // Start loader

      // Prepare the payload
      const payload = {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone,
        message: formData.message,
        subject: 'Request for Insurance & Loan Information',
      };

      fetch('https://www.therightway.in/new/admin/api/loan-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Send payload to API
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.message || 'Failed to send email');
            });
          }
          return response.json();
        })
        .then((data) => {
          Swal.fire({
            title: 'Success!',
            text: data.message || 'Email sent successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          setFormData({
            name: '',
            phone: '',
            email: '',
            message: '',
          });
          setErrors({});
        })
        .catch((error) => {
          console.error('Failed to send email:', error);

          Swal.fire({
            title: 'Error!',
            text: error.message || 'Failed to send email!',
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
        })
        .finally(() => {
          setLoading(false); // Stop loader
        });
    } else {
      console.log('Validation failed');
    }
  };


  // --------------------------------email condition-----------------------------------------------------

  // -----------------------------------faq------------------------------

  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Static FAQ data
  const faqData = [
    {
      question: "Are online insurance is Fake.?",
      answer: "No, if the insurance policies are bought from reputed websites which are under the regulations of the IRDA, the policies would be genuine and would be issued by the insurance company directly."
    },
    {
      question: "What is IDRA",
      answer: "IRDA stands for the Insurance Regulatory and development Authority. It is the apex regulatory body of insurance in India. All insurance companies have to comply with the rules prescribed by the IRDA for selling their insurance policies."
    },
    {
      question: "Are insurance policies all inclusive",
      answer: "No, insurance policies have a list of excluded coverage benefits which are not covered under the plan. These exclusions depend on the type of insurance policy."
    },
    {
      question: "How much coverage should be chosen into insurance plain ?",
      answer: "The coverage level of your insurance policies should be optimum to cover the financial loss which is insured by the policy. In life and health insurance plans, the coverage level should be chosen based on the lifestyle expenses of the insured, the family size, age, income, etc. In case of car and bike insurance policies, the coverage depends on the value of the car and bike"
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredFaqs = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // -------------------------------------------------------------------


  return (
    <>
      <NavbarMain />
      <div className='Insurance-main'>
        <div className="Insurance-page">
          {/* -------------------------------------------------------------------------------- */}
          <div className="Insurance-Top">

            <h3><span></span></h3>
            <h5>Trusted <span> Insurance Portal</span></h5>
            <div className="ins-dbe">
              <div className="dbe">
                <img src="https://cdn.policyx.com/images/health-insurance-homeslide.webp" alt="" />
                <small>Health Insurance</small>
              </div>
              <div className="dbe">
                <img src="https://cdn.policyx.com/images/term-homeslide.webp" alt="" />
                <small>Term Insurance</small>
              </div>
              <div className="dbe">
                <img src="https://cdn.policyx.com/images/investment-homeslide.webp" alt="" />
                <small>Investment</small>
              </div>
              <div className="dbe">
                <img src="https://cdn.policyx.com/images/doct1.png" alt="" />
                <small>Doctor Idemnity</small>
              </div>
            </div>
          </div>
          {/* -------------------------------------------------------------------------------- */}

          <div className="Insurance-two">
            <h3>Why Choose - <small style={{ fontWeight: "700", color: "#015FC9" }}>The Right Way</small></h3>
            <div className="Insurance">
              <div className="ins-left">
                <div className="l-one">
                  <div className="left-l">
                    <h5>Trust</h5>
                    <h6><i class="ri-star-fill"></i> No,misllesing- know your advisor</h6>
                    <h6><i class="ri-star-fill"></i> IRDIA Approved - Indias #1 web aggrigator</h6>
                    <h6><i class="ri-star-fill"></i> Google 4.5 rating , 80% Repeat customers</h6>

                  </div>
                  <div className="left-r">
                    <img src="https://cdn.policyx.com/images/trust-why.webp" alt="" />
                  </div>
                </div>
                <div className="l-one" style={{ backgroundColor: "rgb(249, 114, 64)" }}>
                  <div className="left-l">
                    <h5>Product</h5>
                    <h6><i class="ri-star-fill"></i> Verified leading brands</h6>
                    <h6><i class="ri-star-fill"></i> Product Research before launch</h6>
                    <h6><i class="ri-star-fill"></i> Comparison of features, Brand & Price</h6>

                  </div>
                  <div className="left-r">
                    <img src="https://cdn.policyx.com/images/quality-why.webp" alt="" />
                  </div>
                </div>
                <div className="l-one" style={{ backgroundColor: "green" }}>
                  <div className="left-l">
                    <h5>Price</h5>
                    <h6><i class="ri-star-fill"></i> Free look cancellation benefit</h6>
                    <h6><i class="ri-star-fill"></i> Same Price as directly from brands</h6>
                    <h6><i class="ri-star-fill"></i> Cheaper than offline- online discount</h6>

                  </div>
                  <div className="left-r">
                    <img src="https://cdn.policyx.com/images/rupee-why.webp" alt="" />
                  </div>
                </div>
              </div>
              <div className="ins-left">
                <div className="l-one" style={{ backgroundColor: "#ff3377" }}>
                  <div className="left-l">
                    <h5> Convenience                                   </h5>
                    <h6><i class="ri-star-fill"></i> Quick comparison charts and tables</h6>
                    <h6><i class="ri-star-fill"></i> Free advise - Whatsapp, Email or call.</h6>
                    <h6><i class="ri-star-fill"></i> 100% digital, no documents</h6>

                  </div>
                  <div className="left-r">
                    <img src="https://cdn.policyx.com/images/thumb-why.webp" alt="" />
                  </div>
                </div>
                <div className="l-one" style={{ backgroundColor: "blue" }}>
                  <div className="left-l">
                    <h5>Support</h5>
                    <h6><i class="ri-star-fill"></i> Online free helpdesk</h6>
                    <h6><i class="ri-star-fill"></i> claim support and guidance</h6>
                    <h6><i class="ri-star-fill"></i> we are with you always</h6>

                  </div>
                  <div className="left-r">
                    <img src="https://cdn.policyx.com/images/customer-why.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>


          </div>
          {/* -------------------------------------------------------------------------------- */}

          <div className="Insurance-three">
            <div className="three-left">
              <h6>Home Insurance</h6>
              <h6>Business Insurance</h6>
              <h6>Life Insurance</h6>
              <h6>Travel Insurance</h6>
              <h6>Health Insurance</h6>
              <h6>Fire Insurance</h6>

            </div>
            <div className="three-right">
              <img src="https://pixydrops.com/insur-html/main-html/assets/images/update-10-02-2023/resources/insurance-details-img-2.jpg"  alt="" />
            </div>
          </div>

          {/* -------------------------------------------------------------------------------------------------- */}
          <div className="Insurance-works">
            <div className="Work">

              <div className="work-top">
                <h5>How <small style={{ fontWeight: "700", color: "#015FC9" }}>TheRightWay</small> Works?</h5>
                <span></span>
              </div>
              <div className="work-btm">
                <div className="work1">
                  <div className="work-left">
                    <img src="https://cdn.policyx.com/images/v5.webp" alt="" />
                  </div>
                  <div className="work-right">
                    <h6>Tell Us About Yourself</h6>
                    <p>Share your basic details like name, age, occupation, and income. Our reliable algorithm will take those details into account and fetch you the best premium quotes from the industry-leading insurers.</p>
                  </div>
                </div>
                <div className="work1">
                  <div className="work-left">
                    <img src="https://cdn.policyx.com/images/v4.webp" alt="" />
                  </div>
                  <div className="work-right">
                    <h6>Compare & Select The Right Plan</h6>
                    <p>"Check and compare all the quotes in terms of premium and benefits. After careful consideration, choose the right plan that goes well with your needs and budget. Your and your family's future depends on it."</p>
                  </div>
                </div>
              </div>

              <div className="work-btm">
                <div className="work1">
                  <div className="work-left">
                    <img src="https://cdn.policyx.com/images/v2.webp" alt="" />
                  </div>
                  <div className="work-right">
                    <h6>Pay & Get Policy Within 5 Minutes</h6>
                    <p>"Pay the premium online using any of the online payment modes (like debit card, credit card, or net banking). A soft copy of the policy is usually available instantly. If the policy requires a medical check-up or some documentation, our team will assist you to complete these quickly and reliably."</p>
                  </div>
                </div>
                <div className="work1">
                  <div className="work-left">
                    <img src="https://cdn.policyx.com/images/v1.webp" alt="" />
                  </div>
                  <div className="work-right">
                    <h6>Always There For You</h6>
                    <p>"Once you buy a policy through PolicyX.com, you can access our polite and knowledgeable, highly rated customer service completely free of cost. You can call them up any time to clear your doubts or make changes in your policy or claim support. We will make sure all your queries are handled with care."</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* -------------------------------------------------------------------------------------------------- */}
          <div className="Insurance-Index">
            <div className="index">
              <div className="index-top">
                <small></small>
                <h5> <span>TheRightWay</span>  Insurance Price Index</h5>
                <p>"India's first Health and Term Insurance Price Index. Get actionable insights into recent pricing trends of health and term plans offered by top insurance companies in India. Advanced data analytics to demonstrate quarterly shifts in pricing based on multiple factors like coverage, gender, age, and smoker/non-smoker status."</p>
              </div>
              <div className="index-btm">
                <div className="ib">
                  <div className="ib-img">
                    <img src="https://media.istockphoto.com/id/1295902079/photo/healthcare-business-graph-data-and-growth-doctor-use-calculator-and-calculate-healthcare.webp?a=1&b=1&s=612x612&w=0&k=20&c=fQ2LyEy-68LEKt9RRal5vRHbUtycRkqJ9Pa44v4MutM=" alt="" />
                  </div>
                  <h5>Health Insurance Price Index</h5>
                </div>
                <div className="ib">
                  <div className="ib-img">
                    <img src="https://media.istockphoto.com/id/2072511075/photo/risk-management-is-the-process-of-identifying-assessing-and-mitigating-risks-to-minimize.webp?a=1&b=1&s=612x612&w=0&k=20&c=hcls7SvkRCmfPHf3_CuQ1zrfpdEdV_5ZPcNaTs_OrV4=" alt="" />
                  </div>
                  <h5>Term Insurance Price Index</h5>
                </div>
              </div>
            </div>
          </div>

          {/* -------------------------------------------------------------------------------------------------- */}

          <div className="loan-btm">
      <h5>Apply now & Get Insurance instantly</h5>
      <div className="form-page">
      <div className="form-img">
                  {/* <video src={formImg} autoPlay muted loop></video> */}
                  <img src="https://aviintech.com/wp-content/uploads/2023/10/Ki5QrZeaFB.gif" alt="" />

                </div>
        <form onSubmit={handleSubmit}>
          <div className="ipt">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="ipt">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter Your Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="ipt">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="ipt">
            <label>Message</label>
            <textarea
              name="message"
              placeholder="Type Message Here..."
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <span className="error">{errors.message}</span>}
          </div>
          <div className="ipt">
            <button
              type="submit"
              className={loading ? 'loading' : ''}
              disabled={loading}
            >
              {loading ? <div className="loader"></div> : 'Send a Message'}
            </button>
          </div>
        </form>
      </div>
    </div>

          <Partnership />


          {/* -------------------------------------------------------------------------------------------------- */}

          <div className='Faq'>

            <div className="faq-top">
              <img src={questionMarkImg} alt="" />
              <h5>Frequently asked questions</h5>
              <span>Supporting Subheading</span>
            </div>

            <div className="faqq">

              <div className="f-btm">
                {filteredFaqs.map((faq, index) => (
                  <div className="line" key={index}>
                    <div className="one" onClick={() => handleToggle(index)}>
                      <p>{faq.question}</p>
                      <i className={openIndex === index ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                    </div>
                    {openIndex === index && (
                      <div className="two show">
                        <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                      </div>
                    )}
                  </div>
                ))}


              </div>
            </div>
          </div>

          {/* -------------------------------------------------------------------------------- */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Insurance