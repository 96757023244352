import React, { useEffect, useState } from 'react'
import "./TradingDemat.css"
import NavbarMain from "../../pages/NavbarMain/NavbarMain"
import { GiMoebiusStar } from "react-icons/gi";
import { PiLightbulbFilamentFill } from "react-icons/pi";
import { BiSolidHourglass } from "react-icons/bi";
import Footer from '../../pages/Footer/Footer';


import Lottie from 'react-lottie';
import animationData1 from '../../assets/Animation - 1728393077567.json';


import Swal from 'sweetalert2';
import Demat from './Demat';

const TradingDemat = () => {


       const defaultOptions1 = {
              loop: true,
              autoplay: true,
              animationData: animationData1,
              rendererSettings: {
                     preserveAspectRatio: 'xMidYMid slice',
              },
       };

      

       // --------------------------------email condition-----------------------------------------------------


       const [formData, setFormData] = useState({
              name: '',
              phone: '',
              email: '',
              message: '',
       });

       const [errors, setErrors] = useState({});
       const [loading, setLoading] = useState(false);

       // Real-time field validation function
       const validateField = (name, value) => {
              const newErrors = { ...errors };
              const phoneRegex = /^[0-9]{10}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              switch (name) {
                     case 'name':
                            newErrors.name = value ? '' : 'Name is required';
                            break;
                     case 'phone':
                            newErrors.phone = value
                                   ? phoneRegex.test(value)
                                          ? ''
                                          : 'Phone number must be 10 digits'
                                   : 'Phone number is required';
                            break;
                     case 'email':
                            newErrors.email = value
                                   ? emailRegex.test(value)
                                          ? ''
                                          : 'Email is not valid'
                                   : 'Email is required';
                            break;
                     case 'message':
                            newErrors.message = value ? '' : 'Message is required';
                            break;
                     default:
                            break;
              }

              setErrors(newErrors);
       };

       const handleChange = (e) => {
              const { name, value } = e.target;
              setFormData((prevData) => ({
                     ...prevData,
                     [name]: value,
              }));

              validateField(name, value);
       };

       const validateForm = () => {
              const newErrors = {};
              const phoneRegex = /^[0-9]{10}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              if (!formData.name) {
                     newErrors.name = 'Name is required';
              }

              if (!formData.phone) {
                     newErrors.phone = 'Phone number is required';
              } else if (!phoneRegex.test(formData.phone)) {
                     newErrors.phone = 'Phone number must be 10 digits';
              }

              if (!formData.email) {
                     newErrors.email = 'Email is required';
              } else if (!emailRegex.test(formData.email)) {
                     newErrors.email = 'Email is not valid';
              }

              if (!formData.message) {
                     newErrors.message = 'Message is required';
              }

              setErrors(newErrors);
              return Object.keys(newErrors).length === 0;
       };

       const handleSubmit = (e) => {
              e.preventDefault();

              if (validateForm()) {
                     setLoading(true); // Start loader

                     // Prepare the payload
                     const payload = {
                            name: formData.name,
                            email: formData.email,
                            phone_number: formData.phone,
                            message: formData.message,
                            subject: 'Request for Demat & Trading Information',
                     };

                     fetch('https://www.therightway.in/new/admin/api/loan-enquiry', {
                            method: 'POST',
                            headers: {
                                   'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(payload), // Send payload to API
                     })
                            .then((response) => {
                                   if (!response.ok) {
                                          return response.json().then((errorData) => {
                                                 throw new Error(errorData.message || 'Failed to send email');
                                          });
                                   }
                                   return response.json();
                            })
                            .then((data) => {
                                   Swal.fire({
                                          title: 'Success!',
                                          text: data.message || 'Email sent successfully!',
                                          icon: 'success',
                                          confirmButtonText: 'OK',
                                   });

                                   setFormData({
                                          name: '',
                                          phone: '',
                                          email: '',
                                          message: '',
                                   });
                                   setErrors({});
                            })
                            .catch((error) => {
                                   console.error('Failed to send email:', error);

                                   Swal.fire({
                                          title: 'Error!',
                                          text: error.message || 'Failed to send email!',
                                          icon: 'error',
                                          confirmButtonText: 'Try Again',
                                   });
                            })
                            .finally(() => {
                                   setLoading(false); // Stop loader
                            });
              } else {
                     console.log('Validation failed');
              }
       };


       // --------------------------------email condition-----------------------------------------------------
       const [homeLoanCount, setHomeLoanCount] = useState(0);
       const [overdraftLoanCount, setOverdraftLoanCount] = useState(0);
       const [businessLoanCount, setBusinessLoanCount] = useState(0);
       const [personalLoanCount, setPersonalLoanCount] = useState(0);

       useEffect(() => {
              const incrementCounter = (setCounter, maxValue) => {
                     const interval = setInterval(() => {
                            setCounter((prevCount) => {
                                   if (prevCount < maxValue) {
                                          return prevCount + 50;
                                   } else {
                                          clearInterval(interval);
                                          return maxValue;
                                   }
                            });
                     }, 50);
              };

              // Start all counters
              incrementCounter(setHomeLoanCount, 5000);
              incrementCounter(setOverdraftLoanCount, 10000);
              incrementCounter(setBusinessLoanCount, 45);
              incrementCounter(setPersonalLoanCount, 50);
       }, []);

       // -----------------------------------------------------------------------------------------

       return (
              <>
                     <NavbarMain />
                     <div className='TradingDemat-main'>
                            <div className="TradingDemat-page">
                                   {/* --------------------------------------------------------------------------------------- */}
                                   <div className="demat-top">
                                          <h3><span></span></h3>
                                          <h5>OPEN FREE  <br />  DEMAT & <br /> Trading  ACCOUNT NOW</h5>
                                   </div>


                                   <div className="trading-services">
                                          <div className="t-box">
                                                 <div className="anim">
                                                 <Lottie options={defaultOptions1} height={200} width={150} /> {/* Adjust size as needed */}

                                                 </div>
                                                 <div className="text">
                                                 <h6>TRADE @ FLAT BROKERAGE ₹10/ORDER*</h6>

                                                 </div>
                                          </div>

                                          <div className="t-box">
                                          {/* <video src={videoTade2} autoPlay muted loop></video> */}
                                          <div className="anim">
                                          <img src="https://bestanimations.com/Text/Free/free-61.gif" alt="" />
                                          </div>
                                                 {/* <Lottie options={defaultOptions2} height={200} width={150} /> */}
                                                 <div className="text">
                                                 <h6>OPEN 100% FREE* DEMAT & TRADING A/C!</h6>

                                                 </div>
                                          </div>



                                          <div className="t-box">
                                          {/* <video src={videoTade3} autoPlay muted loop></video> */}
                                                 {/* <Lottie options={defaultOptions3} height={200} width={150} />  */}
                                                 <div className="anim">
                                                      <img src="https://cdnl.iconscout.com/lottie/premium/thumb/female-user-profile-5273097-4424674.gif" alt="" />

                                                 </div>
                                                    <div className="text">
                                                 <h6>ANOTHER MESSAGE OR SERVICE HERE!</h6>

                                                    </div>
                                          </div>


                                   </div>


                                   <div className="demat-one">
                                          <div className="demat-content">
                                                 <div className="d-left">
                                                        <span></span>
                                                        <h5> <small>BENEFITS</small> <br /> of trading account</h5>
                                                        {/* <img src="https://cdni.iconscout.com/illustration/premium/thumb/bank-insurance-8855593-7233537.png" alt="" /> */}

                                                 </div>
                                                 <div className="d-right">
                                                        <div className="dOne">
                                                               <div className="lt">
                                                                      <GiMoebiusStar className='d-icon' />
                                                               </div>
                                                               <div className="rt">
                                                                      <h6>ONE STOP FINANCIAL STORE​​</h6>
                                                                      <p>start trading across all exchanges - Trade in equity, future& options ,commodity, currencies , IPO, mutual funds, etc . using only one platform ​</p>
                                                               </div>
                                                        </div>
                                                        <div className="dOne">
                                                               <div className="lt">
                                                                      <PiLightbulbFilamentFill className='d-icon' />

                                                               </div>
                                                               <div className="rt">
                                                                      <h6>TRADE ACROSS DEVICES​​</h6>
                                                                      <p>Trade anytime, anywhere across devices like desktops, tablets , smartphones or mobile powerful​</p>
                                                               </div>
                                                        </div>
                                                        <div className="dOne">
                                                               <div className="lt">
                                                                      <BiSolidHourglass className='d-icon' />

                                                               </div>
                                                               <div className="rt">
                                                                      <h6>TRADE ACROSS DEVICES ​​                            ​​</h6>
                                                                      <p>Award winning Recommendation & Customized Actionable strategies to Suilt Investment styles & rick profiles.​​</p>
                                                               </div>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>

                                   <Demat />


                                   {/* ------------------------------ */}
                                   <div className="loan-btm">
                                          <h5>Simplifying Investment for you ​</h5>
                                          <p>25  years of trust | 1 CR+ happy customers</p>
                                          <div className="form-page">
                                                 <form onSubmit={handleSubmit}>
                                                        <div className="ipt">
                                                               <label>Name</label>
                                                               <input
                                                                      type="text"
                                                                      name="name"
                                                                      placeholder="Enter Your Name"
                                                                      value={formData.name}
                                                                      onChange={handleChange}
                                                                      required
                                                               />
                                                               {errors.name && <span className="error">{errors.name}</span>}
                                                        </div>
                                                        <div className="ipt">
                                                               <label>Phone Number</label>
                                                               <input
                                                                      type="tel"
                                                                      name="phone"
                                                                      placeholder="Enter Your Phone"
                                                                      value={formData.phone}
                                                                      onChange={handleChange}
                                                                      required
                                                               />
                                                               {errors.phone && <span className="error">{errors.phone}</span>}
                                                        </div>
                                                        <div className="ipt">
                                                               <label>Email</label>
                                                               <input
                                                                      type="email"
                                                                      name="email"
                                                                      placeholder="Enter Your Email"
                                                                      value={formData.email}
                                                                      onChange={handleChange}
                                                                      required
                                                               />
                                                               {errors.email && <span className="error">{errors.email}</span>}
                                                        </div>
                                                        <div className="ipt">
                                                               <label>Message</label>
                                                               <textarea
                                                                      name="message"
                                                                      placeholder="Type Message Here..."
                                                                      value={formData.message}
                                                                      onChange={handleChange}
                                                                      required
                                                               ></textarea>
                                                               {errors.message && <span className="error">{errors.message}</span>}
                                                        </div>
                                                        <div className="ipt">
                                                               <button type="submit" className={loading ? 'loading' : ''} disabled={loading}>
                                                                      {loading ? <div className="loader"></div> : 'Send a Message'}
                                                               </button>         </div>
                                                 </form>

                                                 <div className="form-img">
                                                        {/* <video src={formImg} autoPlay muted loop></video> */}
                                                        <img src="https://aviintech.com/wp-content/uploads/2023/10/Ki5QrZeaFB.gif" alt="" />

                                                 </div>


                                          </div>
                                   </div>
                                   {/* ----------------------------------- */}
                                   <div className="loan-counter">
                                          <h6>Total Number Of Loans We Provide</h6>
                                          <div className="counter-page">
                                                 <div className="loan-box">
                                                        <span>{homeLoanCount.toLocaleString()}+</span>
                                                        <h6>Home Loan</h6>
                                                 </div>
                                                 <div className="loan-box">
                                                        <span>{overdraftLoanCount.toLocaleString()}+</span>
                                                        <h6>Overdraft Loan</h6>
                                                 </div>
                                                 <div className="loan-box">
                                                        <span>{businessLoanCount.toLocaleString()}+</span>
                                                        <h6>Business Loan</h6>
                                                 </div>
                                                 <div className="loan-box">
                                                        <span>{personalLoanCount.toLocaleString()}+</span>
                                                        <h6>Personal Loan</h6>
                                                 </div>
                                          </div>
                                   </div>

                                   {/* --------------------------------------------------------------------------------------- */}
                                   <div className="benefits">
                                          <div className="beni-fits">
                                                 <div className="benefit-top">
                                                        <span></span>
                                                        <h4><small>Benefits</small> Of Demat Account</h4>
                                                 </div>

                                                 <div className="benefit-btm">
                                                        <div className="six">
                                                               <h3>Trading Flexibility:</h3>
                                                               <p>With a trading account, you have the flexibility to buy and sell securities as per your convenience. You can place market orders, limit orders, stop-loss orders, and other types of orders to execute trades at desired price levels.</p>
                                                        </div>
                                                        <div className="six">
                                                               <h3>Risk Management:</h3>
                                                               <p>Trading accounts usually offer risk management tools, such as stop-loss orders, that can help you limit potential losses on your trades. These tools allow you to set predefined price levels at which your positions will be automatically closed to manage risk.</p>
                                                        </div>  <div className="six">
                                                               <h3>Convenience and Accessibility</h3>
                                                               <p>Established brokerage firms prioritize the security of client funds and personal information. They implement robust security measures, encryption, and authentication protocols to safeguard your account from unauthorized access and potential cyber threats.</p>
                                                        </div>  <div className="six">
                                                               <h3>Regulatory Compliance</h3>
                                                               <p>Reputable trading platforms comply with financial regulations and operate under regulatory oversight. This helps ensure transparency, fair practices, and protection for investors.</p>
                                                        </div>  <div className="six">
                                                               <h3>Access to Financial Markets</h3>
                                                               <p>A trading account allows you to participate in various financial markets, such as stocks, bonds, commodities, and foreign exchange. This provides you with opportunities to invest, trade, and diversify your portfolio.
                                                               </p>
                                                        </div>  <div className="six">
                                                               <h3>
                                                                      Investment Opportunities
                                                               </h3>
                                                               <p>Reputable trading platforms often provide access to comprehensive market research, analysis tools, and real-time data. These resources can assist you in evaluating investment opportunities, understanding market trends, and making informed trading decisions.</p>
                                                        </div>  <div className="six">
                                                               <h3>
                                                                      Portfolio Management:</h3>
                                                               <p> A trading account enables you to manage your investment portfolio efficiently. You can monitor your holdings, track performance, and make informed decisions to optimize your portfolio allocation</p>
                                                        </div>  <div className="six">
                                                               <h3>
                                                                      Market Research and Analysis:</h3>
                                                               <p>Reputable trading platforms often provide access to comprehensive market research, analysis tools, and real-time data. These resources can assist you in evaluating investment opportunities, understanding market trends, and making informed trading decisions.</p>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>

                            </div>
                     </div>
                     <Footer />
              </>
       )
}

export default TradingDemat