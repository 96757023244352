import React from 'react'
import "./Testimonals.css"


const Testimonals = () => {
  return (
    <div className='Testimonals'>
          <div className="testimonal-main">
{/* ------------------------------------------------------------------------ */}
                   <div className="testimonal-top">
                          <h6>testimonals</h6>
                          <h5>What our customers are talking about</h5>
                   </div>
                   <div className="testimonal-btm">
                     <div className="testimonal-main">

                    <div className="test-box">
                    <div className="text-top">
                               <div className="test-img">
                              <img src='https://img.freepik.com/premium-photo/happy-smiling-indian-business-man-professional-ceo-using-tablet-computer_1276612-15476.jpg' alt="" />  
                               </div>
                             <div className="headings">
                                 <h5>Amit Sahu  <span>DIRECTOR</span></h5>
                                 <h6><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i></h6>
                             </div>
                             <div className="side-img">
                              <img src="https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-1.png" alt="" />
                             </div>
                    </div>  
                      <div className="text-btm">
                              <p>"Opening a demat account with The Right Way was a pivotal step for us. Their platform is user-friendly and provides all the necessary tools for tracking investments. The customer support team is knowledgeable and always ready to assist with any inquiries."</p>
                      </div>


                    </div> 

                    <div className="test-box">
                    <div className="text-top">
                               <div className="test-img">
                              <img src='https://i.pinimg.com/736x/c3/e9/7a/c3e97aa255c604a1123e554cc12eefdc.jpg' alt="" />  
                               </div>
                             <div className="headings">
                                 <h5>Madhuri Giri  <span>DIRECTOR</span></h5>
                                 <h6><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i><i class="ri-star-s-fill"></i></h6>
                             </div>
                             <div className="side-img">
                              <img src="https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-1.png" alt="" />
                             </div>
                    </div>  
                      <div className="text-btm">
                              <p>"Working with The Right Way has been a game-changer for our organization. Their team provided us with tailored insurance solutions that not only cover our current needs but also anticipate future risks. The claims process is straightforward, and the customer service is exceptional."</p>
                      </div>


                     </div> 



                    </div>        
                    </div>



{/* ------------------------------------------------------------------------ */}

</div>
 </div>
  )
}

export default Testimonals