import React, { useState, useEffect }  from 'react'
import "./Loan.css"
import NavbarMain from "../../pages/NavbarMain/NavbarMain"

import LoanSlider from "../../components/Loan/LoanSlider"

import Swal from 'sweetalert2';

import "../../pages/Questions/Faq.css";
import 'remixicon/fonts/remixicon.css';
import questionMarkImg from "../../assets/questionMark.png"
import Footer from '../../pages/Footer/Footer';
import LoanProduct from './LoanProduct'
import LoanWhy from './LoanWhy'

const Loan = () => {

// --------------------------------email condition-----------------------------------------------------

const [formData, setFormData] = useState({
  name: '',
  phone: '',
  email: '',
  message: '',
});

const [errors, setErrors] = useState({});
const [loading, setLoading] = useState(false);

// Real-time field validation function
const validateField = (name, value) => {
  const newErrors = { ...errors };
  const phoneRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  switch (name) {
    case 'name':
      newErrors.name = value ? '' : 'Name is required';
      break;
    case 'phone':
      newErrors.phone = value
        ? phoneRegex.test(value)
          ? ''
          : 'Phone number must be 10 digits'
        : 'Phone number is required';
      break;
    case 'email':
      newErrors.email = value
        ? emailRegex.test(value)
          ? ''
          : 'Email is not valid'
        : 'Email is required';
      break;
    case 'message':
      newErrors.message = value ? '' : 'Message is required';
      break;
    default:
      break;
  }

  setErrors(newErrors);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));

  // Call validation function on each input change
  validateField(name, value);
};

const validateForm = () => {
  const newErrors = {};
  const phoneRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!formData.name) {
    newErrors.name = 'Name is required';
  }

  if (!formData.phone) {
    newErrors.phone = 'Phone number is required';
  } else if (!phoneRegex.test(formData.phone)) {
    newErrors.phone = 'Phone number must be 10 digits';
  }

  if (!formData.email) {
    newErrors.email = 'Email is required';
  } else if (!emailRegex.test(formData.email)) {
    newErrors.email = 'Email is not valid';
  }

  if (!formData.message) {
    newErrors.message = 'Message is required';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const handleSubmit = (e) => {
  e.preventDefault();

  if (validateForm()) {
    setLoading(true); // Start loader

    // Prepare the payload
    const payload = {
      name: formData.name,
      email: formData.email,
      phone_number: formData.phone,
      message: formData.message,
      subject: 'Request for Insurance & Loan Information',
    };

    fetch('https://www.therightway.in/new/admin/api/loan-enquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload), // Send payload to API
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Failed to send email');
          });
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire({
          title: 'Success!',
          text: data.message || 'Email sent successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
        });
        setErrors({});
      })
      .catch((error) => {
        console.error('Failed to send email:', error);

        Swal.fire({
          title: 'Error!',
          text: error.message || 'Failed to send email!',
          icon: 'error',
          confirmButtonText: 'Try Again',
        });
      })
      .finally(() => {
        setLoading(false); // Stop loader
      });
  } else {
    console.log('Validation failed');
  }
};



// --------------------------------email condition-----------------------------------------------------  

  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Static FAQ data for Loan-related queries
const faqData = [
  {
    question: "What is the eligibility criteria to apply for a loan?",
    answer: "To apply for a loan, you typically need to meet age, income, employment stability, and credit score requirements. Criteria may vary depending on the lender and loan type."
  },
  {
    question: "How is my loan interest rate determined?",
    answer: "Interest rates are based on factors like your credit score, loan amount, tenure, and the type of loan you are applying for. Market conditions and lender policies also play a role."
  },
  {
    question: "Can I repay my loan early without any penalty?",
    answer: "Some lenders allow prepayment without penalties, while others may charge a fee. It's best to confirm the prepayment terms with your lender before applying."
  },
  {
    question: "What documents are required for loan approval?",
    answer: "Common documents include identity proof (like a passport or Aadhaar), income proof (salary slips or ITR), address proof, and bank statements. Requirements may vary across loan types."
  },
  {
    question: "What happens if I miss an EMI payment?",
    answer: "Missing an EMI can affect your credit score and may incur late fees. It's recommended to contact your lender immediately to discuss possible solutions, such as restructuring the loan."
  }
];

  
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  
  const filteredFaqs = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  
  
  // ------------------------------------------------------------------------

  const [homeLoanCount, setHomeLoanCount] = useState(0);
  const [overdraftLoanCount, setOverdraftLoanCount] = useState(0);
  const [businessLoanCount, setBusinessLoanCount] = useState(0);
  const [personalLoanCount, setPersonalLoanCount] = useState(0);

  useEffect(() => {
    const incrementCounter = (setCounter, maxValue) => {
      const interval = setInterval(() => {
        setCounter((prevCount) => {
          if (prevCount < maxValue) {
            return prevCount + 50; 
          } else {
            clearInterval(interval);
            return maxValue; 
          }
        });
      }, 50);
    };

    // Start all counters
    incrementCounter(setHomeLoanCount, 1000);
    incrementCounter(setOverdraftLoanCount, 7000);
    incrementCounter(setBusinessLoanCount, 9500);
    incrementCounter(setPersonalLoanCount, 4000);
  }, []);

  return (
    <>
     <NavbarMain />
    <div className='Loan-main'> 
     <div className="loan-page">
            
            <LoanSlider />
            <LoanProduct />

            <div className="loan-btm">
      <h5>Apply now & Get Insurance instantly</h5>
      <div className="form-page">
      <div className="form-img">
                  {/* <video src={formImg} autoPlay muted loop></video> */}
                  <img src="https://aviintech.com/wp-content/uploads/2023/10/Ki5QrZeaFB.gif" alt="" />
                </div>
        <form onSubmit={handleSubmit}>
          <div className="ipt">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="ipt">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter Your Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="ipt">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="ipt">
            <label>Message</label>
            <textarea
              name="message"
              placeholder="Type Message Here..."
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <span className="error">{errors.message}</span>}
          </div>
          <div className="ipt">
            <button
              type="submit"
              className={loading ? 'loading' : ''}
              disabled={loading}
            >
              {loading ? <div className="loader"></div> : 'Send a Message'}
            </button>
          </div>
        </form>
      </div>
    </div>

                  
 {/* ------------------------------------------------------------------------- */}

    <div className="loan-counter">
      <h5>Total Number Of Loans We Provide</h5>
      <div className="counter-page">
        <div className="loan-box">
          <span>{homeLoanCount.toLocaleString()}+</span>
          <h6>Home Loan</h6>
        </div>
        <div className="loan-box">
          <span>{overdraftLoanCount.toLocaleString()}+</span>
          <h6>Overdraft Loan</h6>
        </div>
        <div className="loan-box">
          <span>{businessLoanCount.toLocaleString()}+</span>
          <h6>Business Loan</h6>
        </div>
        <div className="loan-box">
          <span>{personalLoanCount.toLocaleString()}+</span>
          <h6>Personal Loan</h6>
        </div>
      </div>
    </div>

    <LoanWhy />

 {/* ------------------------------------------------------------------------- */}

 <div className='Faq' style={{backgroundColor:"#fff"}}>

<div className="faq-top">
          <img src={questionMarkImg} alt="" />
      <h5>Frequently asked questions</h5>
</div>     

<div className="faqq">

<div className="f-btm">
  {filteredFaqs.map((faq, index) => (
    <div className="line" key={index}>
      <div className="one" onClick={() => handleToggle(index)}>
        <p>{faq.question}</p>
        <i className={openIndex === index ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
      </div>
      {openIndex === index && (
        <div className="two show">
          <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
        </div>
      )}
    </div>
  ))}


</div>
</div>
  </div>

     </div>
    </div>
    <Footer/>
    </>
  )
}

export default Loan