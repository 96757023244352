import React from 'react'
import "./OurServices.css"
import { PiPoliceCarLight } from "react-icons/pi";
import { BsHeartPulse } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import { LiaHandHoldingHeartSolid } from "react-icons/lia";
import { IoBusinessOutline } from "react-icons/io5";
import { PiFire } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { MdFlightTakeoff } from "react-icons/md";

import mutualImg from "../../assets/fundsImg.jpeg"
import healthIns from "../../assets/health.jpg"
import LifeIns from "../../assets/lifeins.jpg"
import home from "../../assets/home.jpg"

import businessIns from "../../assets/businesloan.jpeg"
import fireIns from "../../assets/firImg.jpeg"
import dematIns from "../../assets/demat.jpeg"
import travelIns from "../../assets/travel.webp"


const OurServices = () => {
          return (
          <div className='OurServices'>
                    <div className="services-main">
          {/* ------------------------------------------------------------------ */}

         <div className="service-top">
            <h6>Our Services</h6>
            <h5>We’re covering all the  <br /> Insurance and Loan fields.</h5>
         </div>

         <div className="service-boxes">
    <div className="services-btm">
        <div className="box">
            <div className="image">
                <img src={mutualImg} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <PiPoliceCarLight className='icon' />
                </div>
                <span></span>
                <h6>Mutual Funds</h6>
                <p>Invest in mutual funds to grow your wealth with expert-managed portfolios.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={healthIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <BsHeartPulse className='icon' />
                </div>
                <span></span>
                <h6>Health Insurance</h6>
                <p>Secure your health with  insurance plans that cover medical emergencies.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={LifeIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <LiaHandHoldingHeartSolid className='icon' />
                </div>
                <span></span>
                <h6>Life Insurance</h6>
                <p>Ensure your family’s financial security with the right life insurance coverage.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={home} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <IoHomeOutline className='icon' />
                </div>
                <span></span>
                <h6>Home Loan</h6>
                <p>Get a hassle-free home loan and turn your dream house into a reality.</p>
            </div>
        </div>
    </div>

    <div className="services-btm">
        <div className="box">
            <div className="image">
                <img src={businessIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <IoBusinessOutline className='icon' />
                </div>
                <span></span>
                <h6>Business Loan</h6>
                <p>Grow your business with flexible loan options tailored to your needs reality flexible.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={fireIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <PiFire className='icon' />
                </div>
                <span></span>
                <h6>Fire Insurance</h6>
                <p>Protect your property from fire damage with our comprehensive coverage.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={dematIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <PiUsersThree className='icon' />
                </div>
                <span></span>
                <h6>Demat Account</h6>
                <p>Open a Demat account for seamless stock market investments.</p>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={travelIns} alt="" />
                {/* <div className="overlay"></div> */}
            </div>
            <div className="text">
                <div className="icon-box">
                    <MdFlightTakeoff className='icon' />
                </div>
                <span></span>
                <h6>Travel Insurance</h6>
                <p>Travel worry-free with our insurance covering unforeseen events abroad.</p>
            </div>
        </div>
    </div>
</div>


          {/* ------------------------------------------------------------------ */}

 </div>
 </div>
          )
}

export default OurServices