import React from 'react'
import "./Partnership.css"

import partner1 from "../../assets/partner1.png"
import partner2 from "../../assets/partner2.png"
import partner3 from "../../assets/partner3.jpeg"
import partner4 from "../../assets/partner4.png"


const Partnership = () => {
  return (
    <div className='Partnership-main'>
          <div className="partners">
{/* ---------------------------------------------------------------------------------------- */}
           <h5> <span>Partnership </span> With Banks</h5>
            <div className="p-btm">
                <div className="one">
                    <img src={partner1} alt="" />
                </div>
                <div className="one">
                    <img src={partner2} alt="" />
                </div>
                <div className="one">
                    <img src={partner3} alt="" />
                </div>
                <div className="one">
                    <img src={partner4} alt="" />
                </div>
            </div>

</div>
</div>
  )
}

export default Partnership