import React from 'react'
import "./AboutUs.css"
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdMovieEdit } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { AiOutlineInsurance } from "react-icons/ai";
import { useNavigate  } from 'react-router-dom';

const AboutUs = () => {

   const navigate = useNavigate();

    const tradingHandler = () => {
      navigate('/demat')
    }
    const dematHandler = () => {
      navigate('/loan')
    }
    const insuranceHandler = () => {
      navigate('/insurance')
    }
    const fundsHandler = () => {
      navigate('/demat')
    }


  return (
    <div className='AboutUs'>
          <div className="about-main">
{/* ------------------------------------------------------------------------------------ */}
         
         <div className="about-top">
                     <h6>About Us / We provide the best policies.</h6>
         </div>

         <div className="about-btm">
          <div className="btm-abt">

          <div className="one">
                  <MdMovieEdit className='about-icon' />
               <h5>TRADING ACCOUNT</h5>  
               <p>“Empower Your Investments. Experience the freedom of a free demat and trading account, unlocking endless opportunities for wealth.”</p>  
                <button onClick={tradingHandler}>Open Now</button>
          </div>  
             <div className="one">
                  <GrMoney className='about-icon' />
               <h5>LOANS</h5>  
               <p>“Instant Cash at Your Fingertips. Get quick access to funds with our hassle-free instant loan service, fulfilling your financial needs.”</p>  
                  <button onClick={dematHandler}>Get Loan</button>
          </div>   
             <div className="one">
                  <AiOutlineInsurance className='about-icon' />
               <h5>INSURANCE</h5>  
               <p>“Protect Your World. Safeguard your future with comprehensive insurance coverage, ensuring peace of mind for you and your loved ones.”</p>  
                     <button onClick={insuranceHandler}>Get Insurance</button>
          </div>    
             <div className="one">
                  <RiMoneyDollarCircleLine className='about-icon' />
                  
               <h5>MUTUAL FUNDS</h5>  
               <p>“Build Your Financial Future. Invest in mutual funds for diversified portfolios, expert management, and the potential for long-term growth!”</p>  
                 <button onClick={fundsHandler}>Invest Now</button>
          </div>    

          </div>  
         </div>


 
{/* ------------------------------------------------------------------------------------ */}
</div>
</div>
  )
}

export default AboutUs