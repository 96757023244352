import React from 'react'
import "./LoanProduct.css"

const LoanProduct = () => {
    return (
        <div className='LoanProduct'>
            <div className='piramain'>
                <div className='pirah2'>
                <small></small>
                    <h2> <span style={{color:"#015FC9"}}>TheRightWay</span> Loan Products</h2>
                </div>
                <div className='piracard'>
                    <div className='pirabox'>
                        <i class="ri-home-heart-line"></i>
                        <h5> <small>Buy New Home</small> </h5>
                    </div>
                    <div className='pirabox'>
                        <i class="ri-building-3-fill"></i>
                        <h5> <small>Home Constuction</small></h5>
                    </div>
                    <div className='pirabox'>
                        <i class="ri-hand-coin-fill"></i>
                        <h5> <small>Business Loan</small></h5>
                    </div>
                    <div className='pirabox'>
                        <i class="ri-hand-coin-line"></i>
                        <h5><small>Personal Loan</small></h5>
                    </div>
                    <div className='pirabox'>
                        <i class="ri-home-office-line"></i>
                        <h5><small>Loan Against Property</small></h5>
                    </div>
                    <div className='pirabox'>
                        <i class="ri-ancient-gate-fill"></i>
                        <h5><small>Secured Business Loan</small></h5>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LoanProduct