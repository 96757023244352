import React from 'react'
import AboutUs from '../../pages/AboutUs/AboutUs'
import OurServices from '../../pages/OurServices/OurServices'
import Testimonals from '../../pages/Testimonals/Testimonals'
import "./Home.css"
import NavbarMain from '../../pages/NavbarMain/NavbarMain'
import Faq from '../../pages/Questions/Faq'
import Footer from '../../pages/Footer/Footer'
import Whowe from '../../pages/Whoweare/Whowe'
import Partnership from '../../pages/Partnership/Partnership'

const Home = () => {
  return (
    <>
      <div className='Home'>

        <NavbarMain />
        {/* --------------------------------------------------------------------------------------------- */}

        <div className="Home-page">
          <div className="background-container">
            {/* <img className="bg-shape bg-shape-1" src="https://media.istockphoto.com/id/1629526439/photo/the-real-estate-agent-forms-a-home-with-the-agent-and-the-client-discusses-the-contract-to.jpg?s=612x612&w=0&k=20&c=4EIq3J5mpTU102rLHHrgoIft4a9TH2PxgMvRD6lRtYQ=" alt="Shape 1" /> */}
            <img className="bg-shape bg-shape-2" src="https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png" alt="Shape 2" />
          </div>

          <div className="home-main">
            <div className="left-main">
              <small></small>
              <h5>Insurance for the better family <span>life.</span></h5>
              <p>Protect your health, home, and future with tailored insurance solutions – peace of mind for life’s unexpected moments.</p>
              {/* <button>Let's Get Started</button> */}
            </div>
            <div className="right-main">
              {/* <img src="https://media.istockphoto.com/id/1629526439/photo/the-real-estate-agent-forms-a-home-with-the-agent-and-the-client-discusses-the-contract-to.jpg?s=612x612&w=0&k=20&c=4EIq3J5mpTU102rLHHrgoIft4a9TH2PxgMvRD6lRtYQ=" alt="Shape 1" alt="" /> */}
            </div>
          </div>
        </div>


        {/* ---------------------------------------------------------------------------------------------  */}
        <OurServices />
        <Whowe />
        <AboutUs />
        <Testimonals />
        <Partnership />

        <Faq />
      </div>

      <Footer />
    </>
  )
}

export default Home