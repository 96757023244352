import React, { useState, useEffect, useRef } from 'react';
import './NavbarMain.css';
import { Link, useLocation } from 'react-router-dom';
import mainLogo from '../../assets/right_way.png';
import { useNavigate } from 'react-router-dom';

const NavbarMain = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current path
  const navigate = useNavigate();
  const navRef = useRef(null); // Reference for the navbar container

  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu
  };

  const backHandler = () => {
    window.scrollTo(0, 0); // Scroll to top when clicking the logo
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever the location changes
  }, [location]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu if clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='NavbarMain'>
      <div className='navbar-main'>
        <div className='nav' ref={navRef}> {/* Attach ref here */}
          {/* Left Section: Logo */}
          <div className='nav-left'>
            <div className='logo' onClick={backHandler}>
              <img style={{cursor:"pointer"}} src={mainLogo} alt='Logo' />
            </div>
          </div>

          {/* Toggle Button for Mobile */}
          <div className='menu-toggle' onClick={toggleMenu}>
            <i className='ri-menu-line'></i> {/* Menu Icon */}
          </div>

          {/* Middle Section: Links */}
          <div className={`nav-mid ${menuOpen ? 'open' : ''}`}>
            <Link
              style={{ 
                textDecoration: 'none', 
                color: isActive('/') ? 'blue' : 'rgba(0, 0, 0, 0.808)', 
                fontWeight: '600' 
              }}
              className='nav-list'
              to='/'
            >
              HOME
            </Link>
            <Link
              style={{ 
                textDecoration: 'none', 
                color: isActive('/insurance') ? 'blue' : 'rgba(0, 0, 0, 0.808)', 
                fontWeight: '600' 
              }}
              className='nav-list'
              to='/insurance'
            >
              INSURANCE
            </Link>
            <Link
              style={{ 
                textDecoration: 'none', 
                color: isActive('/loan') ? 'blue' : 'rgba(0, 0, 0, 0.808)', 
                fontWeight: '600' 
              }}
              className='nav-list'
              to='/loan'
            >
              LOAN
            </Link>
            <Link
              style={{ 
                textDecoration: 'none', 
                color: isActive('/demat') ? 'blue' : 'rgba(0, 0, 0, 0.808)', 
                fontWeight: '600' 
              }}
              className='nav-list'
              to='/demat'
            >
              DEMAT & TRADING
            </Link>
          </div>

          {/* Right Section: Contact */}
          <div className={`nav-right ${menuOpen ? 'open' : ''}`}>
            <i className='ri-phone-fill'></i>
            <a href="tel:+918817507815" style={{ textDecoration: 'none', color: 'inherit' }}>
              <h5>+91 88175 07815</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMain;
