import React from 'react'
import "./LoanWhy.css"

const LoanWhy = () => {
  return (
    <div className='LoanWhy'>
        <div className='pirawhymain'>
               <div className='pirawhycontent'>
                    <small></small>
                    <h3> <span style={{color:"#015FC9"}}>Why</span> Choose Us</h3>
                    <p className='mt-3'>THE RIGHT WAY  its core, believes we are a company that is of the people of Bharat and for the people of Bharat. Our story has been one of steady change. We entered the retail finance area with housing finances and now offer business loans and personal loans. We use customer feedback and new market opportunities to create long-term, value-driven financial services. At THE RIGHT WAY , we put an emphasis on digitisation and online lending, while still giving our valued customers a human touch and expanding branches all over Bharat. We have come a long way already and want to keep going. </p>
                    <p className='mt-4'> Our customized home loan solutions simplify your home-buying experience. Here is why THE RIGHT WAY  has emerged as the leading home loan provider in India:</p>
               </div>
               <div className='pirawhyboxes'>
                    <div className='pirawhybox'>
                    <i class="ri-hand-coin-line"></i>
                    <h5>Simple, Streamlines, Hassle-Free Process</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-time-line"></i>
                    <h5>Speedy Loan Processing</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-cloud-off-fill"></i>
                    <h5>Instant Sanction & Disbursal</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-shapes-fill"></i>
                    <h5>Reasonable Interest Rates</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-creative-commons-nc-line"></i>
                   
                    <h5>No Hidden Charges</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-sim-card-2-fill"></i>
                    <h5>Application to Disbursal Online</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-creative-commons-by-line"></i>
                    <h5>Flexible and Easy Repayment Options</h5>
                    </div>
                    <div className='pirawhybox'>
                    <i class="ri-creative-commons-line"></i>
                    <h5>Easy Documentation</h5>
                    </div>

               </div>
        </div>
    </div>
  )
}

export default LoanWhy