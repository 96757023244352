import "./Faq.css";
import { useState } from 'react';
import 'remixicon/fonts/remixicon.css';
import questionMarkImg from "../../assets/questionMark.png"

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Static FAQ data
  const faqData = [
    {
      question: "How is therightway  different from other insurance sites?",
      answer: " There's no shortage of companies out there offering fast or easy insurance coverage, but we think a marketplace is the best way to compare them all — and Policygenius combines that marketplace experience with online tools, an expansive educational library, and guidance from real, licensed humans to help you get covered with confidence."
    },
    {
      question: "How does therightway  make money?",
      answer: "Yes! All insurance brokers are required by law to be licensed in each state they do business."
    },
    {
      question: "Is therightway a corporate affiliate of any insurance company?",
      answer: "No. We’re an independent insurance broker, which means that we're not owned by an insurance company, nor do we own one. Instead, we sell insurance policies from multiple insurers without bias or favor toward any of them. It’s important to us that we help you find the right insurance policy for your needs — not which company you buy it from."
    },
    {
      question: "Is it cheaper to buy insurance through therightway?",
      answer: "Insurance rates are regulated by law, which means that no company, broker, or agent can offer you a discount on a policy. That doesn't mean you can't find ways to save money, though! Each insurer calculates risk differently, and they all strive to offer policies at competitive prices. "
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredFaqs = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <div className='Faq'>

          <div className="faq-top">
                    <img src={questionMarkImg} alt="" />
                <h5>Frequently asked questions</h5>
          </div>     

        <div className="faqq">

          <div className="f-btm">
            {filteredFaqs.map((faq, index) => (
              <div className="line" key={index}>
                <div className="one" onClick={() => handleToggle(index)}>
                  <p>{faq.question}</p>
                  <i className={openIndex === index ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                </div>
                {openIndex === index && (
                  <div className="two show">
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                  </div>
                )}
              </div>
            ))}
        
        
          </div>
        </div>
      </div>
  );
};

export default Faq;
