import React from 'react'
import "./Whowe.css"

const Whowe = () => {
  return (
    <div className='Whowe-main'>
          <div className="Who-page">
{/* -------------------------------------------------------------------------------------- */}
               <div className="W-top">
                   <div className="w-left">
                        <img src="https://savechildlife.org/uploads/who-we-are-1.png" alt="" />
                   </div>
                   <div className="w-right">
                    <h6>THE RIGHT WAY</h6>
                    <p>Welcome to The Right Way, THE RIGHT WAY leading Financial  service provider dedicated to delivering exceptional services and support to our valued customers. With a focus on reliability, convenience, and customer satisfaction, we offer a wide range of services tailored to meet the unique needs of individuals and businesses.</p>
                      
                         <h6>Our Mission</h6>
                         <p>At The Right Way, our mission is to provide top-notch services that add value to the lives of our customers. We strive to offer reliable, innovative, and cost-effective solutions while ensuring exceptional customer support and satisfaction.</p>

                   </div>
               </div>
           

</div>
    </div>
  )
}

export default Whowe