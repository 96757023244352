
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Loan from './components/Loan/Loan';
import Insurance from './components/Insurance/Insurance';
import TradingDemat from './components/TradingDemat/TradingDemat';
import Footer from './pages/Footer/Footer';
import "./App.css"




function App() {
  return (

   <div>
    <Router>
    <Routes >
    <Route path='/' element={<Home />} />
    <Route path='/loan' element={<Loan />} />
    <Route path='/insurance' element={<Insurance />} />
    <Route path='/demat' element={<TradingDemat />} />
    <Route path='/footer' element={<Footer />} />

    </Routes>
    </Router>
    
   </div>
   
   
  );
}

export default App;
